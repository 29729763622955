<template>
<header class="header-top">
    <nav class="navbar navbar-light">
        <div class="navbar-left">
            <!-- <a @click="toggleAside" class="sidebar-toggle">
                <img class="svg" src="/img/svg/bars.svg" alt="img"></a> -->
            <a @click="toggleAside"  class="navbar-brand" href="#"><img class="svg dark icon-shadow" style="max-height: 30px;" src="/images/ecotechwhite.png" alt="svg"></a>
            <div class="d-block d-md-block d-lg-none">
                <span class="page-name">{{GET_pageName}}</span>
            </div>
        </div>
        <!-- ends: navbar-left -->

        <div class="navbar-right">
            <ul class="navbar-right__menu">

                <li class="nav-notification">
                    <a :class="`local pointer ${getLocal=='en'?'active':''}`" @click="setLocal('en')" >EN</a><span>|</span><a :class="`local pointer ${getLocal=='th'?'active':''}`" @click="setLocal('th')">TH</a>
                </li>
                <!-- <li class="nav-notification">
                    <div class="dropdown-custom">
                        <a href="javascript:;" class="nav-item-toggle">
                            <span data-feather="bell"></span></a>
                        <div class="dropdown-wrapper">
                            <h2 class="dropdown-wrapper__title">Notifications <span class="badge-circle badge-warning ml-1">0</span></h2>
                            <ul>
                                <li class="nav-notification__single nav-notification__single--unread d-flex flex-wrap">
                                    <div class="nav-notification__type nav-notification__type--primary">
                                        <span data-feather="inbox"></span>
                                    </div>
                                    <div class="nav-notification__details">
                                        <p>
                                            <a href="" class="subject stretched-link text-truncate" style="max-width: 180px;">James</a>
                                            <span>sent you a message</span>
                                        </p>
                                        <p>
                                            <span class="time-posted">5 hours ago</span>
                                        </p>
                                    </div>
                                </li>
                   
                            </ul>
                            <a href="" class="dropdown-wrapper__more">See all incoming activity</a>
                        </div>
                    </div>
                </li> -->

                <!-- ends: .nav-flag-select -->
                <li class="nav-author">
                    <div class="dropdown-custom">
                        <a href="javascript:;" class="nav-item-toggle ">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 16C13.5 15.6022 13.342 15.2206 13.0607 14.9393C12.7794 14.658 12.3978 14.5 12 14.5C11.6022 14.5 11.2206 14.658 10.9393 14.9393C10.658 15.2206 10.5 15.6022 10.5 16C10.5 16.3978 10.658 16.7794 10.9393 17.0607C11.2206 17.342 11.6022 17.5 12 17.5C12.3978 17.5 12.7794 17.342 13.0607 17.0607C13.342 16.7794 13.5 16.3978 13.5 16ZM13.5 7C13.5 6.60218 13.342 6.22064 13.0607 5.93934C12.7794 5.65804 12.3978 5.5 12 5.5C11.6022 5.5 11.2206 5.65804 10.9393 5.93934C10.658 6.22064 10.5 6.60218 10.5 7C10.5 7.39782 10.658 7.77936 10.9393 8.06066C11.2206 8.34196 11.6022 8.5 12 8.5C12.3978 8.5 12.7794 8.34196 13.0607 8.06066C13.342 7.77936 13.5 7.39782 13.5 7ZM13.5 11.5C13.5 11.1022 13.342 10.7206 13.0607 10.4393C12.7794 10.158 12.3978 10 12 10C11.6022 10 11.2206 10.158 10.9393 10.4393C10.658 10.7206 10.5 11.1022 10.5 11.5C10.5 11.8978 10.658 12.2794 10.9393 12.5607C11.2206 12.842 11.6022 13 12 13C12.3978 13 12.7794 12.842 13.0607 12.5607C13.342 12.2794 13.5 11.8978 13.5 11.5Z" fill="#272B41"/>
                            </svg>
                        </a>
                        <div class="dropdown-wrapper">
                            <div class="nav-author__info">
                                <div class="author-img">
                                    <img class="img-nav-profile rounded-circle"  :src="`${$store.getters['auth/getUser'].USER_IMG?$store.getters['auth/getUser'].USER_IMG:'/images/ecotechwhite.png'}`" alt="" >
                                </div>
                                <div>
                                    <h6>{{$store.getters['auth/getUser'].NAME}} {{$store.getters['auth/getUser'].LNAME}}</h6>
                                    <span>{{$store.getters['auth/getUser'].COMPANY_NAME}}</span>
                                </div>
                            </div>
                            <div class="nav-author__options">
                                <!-- <ul>
                                    <li>
                                        <a href="">
                                            <span data-feather="user"></span> Profile</a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <span data-feather="settings"></span> Settings</a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <span data-feather="key"></span> Billing</a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <span data-feather="users"></span> Activity</a>
                                    </li>
                                    <li>
                                        <a href="">
                                            <span data-feather="bell"></span> Help</a>
                                    </li>
                                </ul> -->
                                <a @click="BTNlogout" class="nav-author__signout">
                                    <span data-feather="log-out"></span> Sign Out</a>
                            </div>
                        </div>
                        <!-- ends: .dropdown-wrapper -->
                    </div>
                </li>
                <!-- ends: .nav-author -->
            </ul>
          
        </div>
        <!-- ends: .navbar-right -->
    </nav>
</header>

</template>

<script>
export default {
  name: 'Navbar',
  methods: {
    toggleAside(){
      this.$store.dispatch('display/toggle_Aside')
    },
    BTNlogout(){
      this.$store.dispatch('auth/logout')
      this.$router.push('/login');
    },
    setLocal(para_local){
        this.$store.dispatch('display/setLocal',para_local)
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_pageName:function(){
      return this.$store.getters['display/GET_pageName']
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.local.active{
    color: #5f63f2;
}
.icon-shadow{
    -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
}
.page-name{
  background: -webkit-linear-gradient(#2F55A4, #352B6C);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}
</style>
