// import AuthService from '../services/auth.service';
import * as main from '../services/main.service';
import * as constant from '../services/constant';

export const localstorage = {
    namespaced: true,
    state: {
        plantId:main.readLocalStorageEncrypt('pid'),
        inverterId:main.readLocalStorageEncrypt('ivid'),
        inverterName:main.readLocalStorageEncrypt('ivname'),
        dateToday:main.readLocalStorageEncrypt('dd'),
        dateMonth:main.readLocalStorageEncrypt('dm'),
        dateYear:main.readLocalStorageEncrypt('dy'),
        dateType:main.readLocalStorageEncrypt('dt')
    },
    actions: {
        SET_plantId({ commit },value) {
            main.saveLocalStorageEncrypt('pid',value)
            commit('MU_plantId', value);
        },
        SET_inverterId({ commit },value) {
            main.saveLocalStorageEncrypt('ivid',value)
            commit('MU_inverterId', value);
        },
        SET_inverterName({ commit },value) {
            main.saveLocalStorageEncrypt('ivname',value)
            commit('MU_inverterName', value);
        },
        SET_dateToday({ commit },value) {
            console.log(value)
            main.saveLocalStorageEncrypt('dd',value)
            commit('MU_dateToday', value);
        },
        SET_dateMonth({ commit },value) {
            console.log(value)
            main.saveLocalStorageEncrypt('dm',value)
            commit('MU_dateMonth', value);
        },
        SET_dateYear({ commit },value) {
            console.log(value)
            main.saveLocalStorageEncrypt('dy',value)
            commit('MU_dateYear', value);
        },
        SET_dateType({ commit },value) {
            console.log(value)
            main.saveLocalStorageEncrypt('dt',value)
            commit('MU_dateType', value);
        }
    },
    getters: {
        GET_plantId(state) {
            return state.plantId;
        },
        GET_inverterId(state) {
            return state.inverterId;
        },
        GET_inverterName(state) {
            return state.inverterName;
        },
        GET_dateToday(state) {
            return state.dateToday;
        },
        GET_dateMonth(state) {
            return state.dateMonth;
        },
        GET_dateYear(state) {
            return state.dateYear;
        },
        GET_dateType(state) {
            return state.dateType;
        },
    }, 
    mutations: {
        MU_plantId(state, value) {
            state.plantId = value;
        },
        MU_inverterId(state, value) {
            state.inverterId = value;
        },
        MU_inverterName(state, value) {
            state.inverterName = value;
        },
        MU_dateToday(state, value) {
            state.dateToday = value;
        },
        MU_dateMonth(state, value) {
            state.dateMonth = value;
        },
        MU_dateYear(state, value) {
            state.dateYear = value;
        },
        MU_dateType(state, value) {
            state.dateType = value;
        }
    }
};
