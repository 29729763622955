import { createStore } from 'vuex'
import { display } from './display.module';
import { localstorage } from './localstorage.module';
import { service } from './service.module';
import { auth } from './auth.module';
export default createStore({
  modules: {
    auth:auth,
    display:display,
    localstorage:localstorage,
    service:service
  }
})
