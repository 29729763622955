import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './plugins/i18n';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { io } from "socket.io-client";
import * as constant from './services/constant';
// createApp(App).use(store).use(router).use(VueSweetalert2).mount('#app')
const app = createApp(App)
app.use(store)
app.use(i18n)
app.use(router)
app.use(VueSweetalert2)
app.config.globalProperties.$socket = io.connect(constant.socket_URL);
app.mount('#app')