<template>
    <main class="main-content" style=" background-color:white; " >

        <div class="signUP-admin h-vw-100 ">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-md-6 position-relative p-0">
                        <img class=" eco-img-bg" src="/images/icon/eco-bg.png" alt="">
                        <img class="eco-img-login-logo" src="/images/icon/eco-login.png" alt="">
                    </div><!-- End: .col-xl-4  -->
                    <div class="col-md-6 pt-5">
                        <div class="d-flex  card-flex-login justify-content-center align-items-center">
                            <div class="row card-login" >
                                <div class="col-12">
                                        <div class=" border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                                            <div class="edit-profile__title">
                                                <p class="text-center h5" >WELCOME TO</p>
                                            </div>
                                            <div class="edit-profile__title mt-3 px-5">
                                                <img class="w-100" src="/images/icon/eco-name.png" alt="">
                                            </div>
                                            <div class="edit-profile__title mt-3">
                                                <p class="text-center font-signin" >Sign In to your account</p>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="edit-profile__body">
                                                <div class="form-group mb-20">
                                                    <label for="username">Username</label>
                                                    <input type="text" class="form-control" v-model="loginformData.username"   placeholder="Username or Email">
                                                </div>
                                                <div class="form-group mb-15">
                                                    <label for="password-field">password</label>
                                                    <div class="position-relative">
                                                        <input v-on:keyup.enter="loginFN" v-model="loginformData.password" placeholder="Password" :type="passwordFieldType" class="form-control" name="password" >
                                                        <div v-if="passwordFieldType=='password'" @click="viewpass" class="fa fa-fw fa-eye-slash text-light fs-16 field-icon toggle-password2"></div>
                                                        <div @click="viewpass" class="fa fa-fw fa-eye text-light fs-16 field-icon toggle-password2"></div>
                                                    </div>
                                                </div>
                                                <div class="signUp-condition signIn-condition">
                                                    <div class="checkbox-theme-default custom-checkbox ">
                                                        <input class="checkbox"   type="checkbox" id="check-1">
                                                        <label for="check-1">
                                                            <span class="checkbox-text">Keep me logged in</span>
                                                        </label>
                                                    </div>
                                                    <a class="font-color-main-sec" href="forget-password.html">forget password</a>
                                                </div>
                                                <div class="button-group d-flex pt-1 justify-content-md-start justify-content-center">
                                                    <button @click="loginFN" class="btn btn-eco mx-auto btn-default btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn ">
                                                        sign in
                                                    </button>
                                                </div>
                                            </div>
                                        </div><!-- End: .card-body -->
                                    </div>
                            </div>
                        </div>
                    </div><!-- End: .col-xl-8  -->
                </div>
            </div>
        </div><!-- End: .signUP-admin  -->

    </main>
</template>

<script>
// @ is an alias to /src
import * as serviceAPI  from '../services/API.service';
import * as serviceMain  from '../services/main.service';

export default {
  name: 'login',
  data: function () {
    return {
      loginformData:{
          username:'',
          password:''
      },
      passwordFieldType:'password'
    }
  },
  methods: {  
    async loginFN() {
        try {
            let data = {        
            "PER_ID": this.loginformData.username,
            "PER_PASS": this.loginformData.password
            }
            let getAPI = await serviceAPI.call_API('post','user/login',data);
            console.log("🚀 ~ file: Login.vue ~ line 146 ~ loginFN ~ getAPI", getAPI)
            let getEncodeAccess_token = btoa(btoa(getAPI.data.access_token))
            localStorage.setItem('user', getEncodeAccess_token);
            this.$store.dispatch('auth/login')
            //
            this.$router.push('/welcome');
        } catch (error) {
            console.log("error")
            console.log(error)
            serviceMain.showErrorAlert(this,error)
        }
    },
    cklogIn:async function () {
        if (this.$store.getters['auth/getloggedIn']) {
            this.$router.push('/welcome');
        }
    },
    viewpass() {
        this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    }

  },
  mounted() {
        this.cklogIn()

  }
}

</script>

<style scoped>
.h-vw-100{
    height: 100vw;
}
.eco-img-bg{
    width: 140%;
    /* height: 80vh; */
}
.eco-img-login-logo{
    position: absolute;
    top:50%;
    left:70%;
    transform: translate(-50%,-50%);
    max-width: 500px;
}
@media only screen and (max-width: 1600px) {
    .eco-img-bg{
        width: 140%;
    }
    .eco-img-login-logo{
        position: absolute;
        top:50%;
        left:70%;
        transform: translate(-50%,-50%);
        max-width: 400px;
    }
}
@media only screen and (max-width: 1300px) {
    .eco-img-bg{
        width: 140%;
    }
    .eco-img-login-logo{
        position: absolute;
        top:50%;
        left:70%;
        transform: translate(-50%,-50%);
        max-width: 300px;
    }
}
@media only screen and (max-width: 768px) {
    .eco-img-bg{
        width: 100%;
        height: auto;
    }   
    .eco-img-login-logo{
        position: absolute;
        top:60%;
        left:50%;
        transform: translate(-50%,-50%);
        max-width: 150px;
    }
}

.card-body{
    border:1px rgba(51, 51, 51, 0.25) solid;
    border-radius:25px;
}
.btn-eco{
    background: rgb(47,85,164);
  background: linear-gradient(180deg, rgba(47,85,164,1) 0%, rgba(53,43,108,1) 100%);
  color: white;
}
.font-signin{
    font-size:1rem;
}
.card-flex-login{
    height: 100%;
}
.card-login{
    
    max-width:500px;
}
</style>

