// import AuthService from '../services/auth.service';
import * as main from '../services/main.service';
import * as constant from '../services/constant';

export const service = {
    namespaced: true,
    state: {
        socketSetReload:'',
    },
    actions: {
        SET_socketSetReload({ commit }) {
            commit('MU_socketSetReload', Date.now());
        },
    },
    getters: {
        GET_socketSetReload(state) {
            return state.socketSetReload;
        },

    }, 
    mutations: {
        MU_socketSetReload(state, value) {
            state.socketSetReload = value;
        },

    }
};
